$(document).ready(function() {
  $("#fullpage-eobs").fullpage({
    anchors: [
      "project",
      "brief",
      "tldr",
      "login-screen",
      "patient-profile",
      "vital-signs",
      "blood-pressure-obesrvations",
      "eobs-flow",
      "outcomes",
      "reflections"
    ],
    menu: "#menu-eobs",
    //options here
    licenseKey: "FC4EEE34-EB8E4AEA-8707DBBD-FF658830",
    lazyLoading: false,
    autoScrolling: false,
    scrollBar: false,
    // scrollHorizontally: true
    bigSectionsDestination: "top" ,
    showActiveTooltip: "true",
    navigation: false,
    navigationPosition: "right",
    navigationTooltips: [
      "Project",
      "Brief",
      "TL;DR",
      "Final Flow",
      "Patient Profile",
      "Vital Signs",
      "Blood Pressure",
      "Login screen",
      "Outcomes",
      "Reflections"
    ],
    scrollOverflow: false,
    fitToSection: false
  });
});
