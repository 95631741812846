$(document).ready(function () {
  $("#fullpage-surgical-noting").fullpage({
    anchors: [
      "project",
      "brief",
      "research",
      "concepts",
      "final-design",
      "reflections"
    ],
    menu: "#menu-noting",
    //options here
    licenseKey: "FC4EEE34-EB8E4AEA-8707DBBD-FF658830",
    lazyLoading: false,
    autoScrolling: false,
    scrollBar: false,
    // recordHistory: false,
    // scrollHorizontally: true
    bigSectionsDestination: "top",
    showActiveTooltip: "false",
    navigation: false,
    navigationPosition: "right",
    navigationTooltips: [
      "Project",
      "Brief",
      "Research",
      "Concepts",
      "Final Design",
      "Reflections"
    ],
    scrollOverflow: false,
    fitToSection: false
  });
  //methods
  // $.fn.fullpage.setAllowScrolling(false);
});
