console.log("hello there yo");

// Loading Animation
window.addEventListener("load", function () {
    loader = document.querySelector(".loader");
    loader.className += " hidden";
});
window.addEventListener("load", function () {
  loader = document.querySelector(".no-scroll");
  loader.className += " hidden";
});



$(document).ready(function() {

    //-----------------------------------------------------------
    // Homepage Video Colour effect
    //-----------------------------------------------------------
      $(".video-style-link").hover(function(){
        $(".video-black-white").toggleClass("video-colour");
        console.log("im coloured yay")
      });
    //-----------------------------------------------------------
    // Case Studies Video hover
    //-----------------------------------------------------------
    $(".hover-style-first").hover(function(){
      $(".hover-style-container-first").toggleClass("hover-active-first");
      console.log("hovered1");
    });
    $(".hover-style-second").hover(function(){
      $(".hover-style-container-second").toggleClass("hover-active-second");
    });
    $(".hover-style-third").hover(function(){
      $(".hover-style-container-third").toggleClass("hover-active-third");
    });
    $(".hover-style-fourth").hover(function(){
      $(".hover-style-container-fourth").toggleClass("hover-active-fourth");
    });
    //-----------------------------------------------------------
    // Main Menu Button
    //-----------------------------------------------------------
    $("#menu-container-toggle").click(function() {
      $(this).toggleClass("close");
      $(".slide-in-left-container,.slide-in-right-container,.main-menu,.return-container").toggleClass("open");
      console.log("opened");
    });
    //-----------------------------------------------------------
    // Menu video BG
    //-----------------------------------------------------------

    $( ".toggle-video-bg-first" ).hover(function() {
      $(".video-background-container-first").toggleClass("open");
      console.log("hovered1");
    });
    $( ".toggle-video-bg-second" ).hover(function() {
      $(".video-background-container-second").toggleClass("open");
      console.log("hovered2");
    });
    $( ".toggle-video-bg-third" ).hover(function() {
      $(".video-background-container-third").toggleClass("open");
      console.log("hovered3");
    });
    $( ".toggle-video-bg-fourth" ).hover(function() {
      $(".video-background-container-fourth").toggleClass("open");
      console.log("hovered4");
    });

    $('#menu-container-toggle').click(function () {
      var mediaVideos = $("#video-background-first-video, #video-background-second-video, #video-background-third-video, #video-background-fourth-video");
      mediaVideos.each(function() {
          var mediaVideo = this;
          if (mediaVideo.paused) {
              mediaVideo.play();
          } else {
              mediaVideo.pause();
         }
      });
   });
});

