$(document).ready(function () {
  $("#fullpage-whiteboard").fullpage({
    anchors: [
      "project",
      "brief",
      "tldr",
      "whiteboard",
      "insights",
      "transfer",
      "specialist-mdt-review",
      "alerts",
      "discharge",
      "outcomes",
      "reflections"
    ],
    menu: "#menu-whiteboard",
    //options here
    licenseKey: "FC4EEE34-EB8E4AEA-8707DBBD-FF658830",
    lazyLoading: false,
    autoScrolling: false,
    scrollBar: false,
    // scrollHorizontally: true
    bigSectionsDestination: "top",
    showActiveTooltip: "false",
    navigation: false,
    navigationPosition: "right",
    navigationTooltips: [
      "Project",
      "Brief",
      "TL;DR",
      "Whiteboard",
      "Insights",
      "Patient Transfer",
      "Specialist MDT Review",
      "Alerts",
      "Discharge",
      "Outcomes",
      "Reflections"
    ],
    scrollOverflow: false,
    fitToSection: false
  });
  //methods
  // $.fn.fullpage.setAllowScrolling(false);
});

